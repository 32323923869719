/* rubik-300normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Rubik Light '),
    local('Rubik-Light'),
    url(/static/media/rubik-latin-300.3492b2c7.woff2) format('woff2'), 
    url(/static/media/rubik-latin-300.55dc390c.woff) format('woff'); /* Modern Browsers */
}

/* rubik-300italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Rubik Light italic'),
    local('Rubik-Lightitalic'),
    url(/static/media/rubik-latin-300italic.edfcfa71.woff2) format('woff2'), 
    url(/static/media/rubik-latin-300italic.2f49fb9b.woff) format('woff'); /* Modern Browsers */
}

/* rubik-400normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Rubik Regular '),
    local('Rubik-Regular'),
    url(/static/media/rubik-latin-400.cebc4ea4.woff2) format('woff2'), 
    url(/static/media/rubik-latin-400.21d865a8.woff) format('woff'); /* Modern Browsers */
}

/* rubik-400italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Rubik Regular italic'),
    local('Rubik-Regularitalic'),
    url(/static/media/rubik-latin-400italic.2be26eb4.woff2) format('woff2'), 
    url(/static/media/rubik-latin-400italic.9c9c9c0f.woff) format('woff'); /* Modern Browsers */
}

/* rubik-500normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Rubik Medium '),
    local('Rubik-Medium'),
    url(/static/media/rubik-latin-500.96c6954c.woff2) format('woff2'), 
    url(/static/media/rubik-latin-500.614d1a19.woff) format('woff'); /* Modern Browsers */
}

/* rubik-500italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Rubik Medium italic'),
    local('Rubik-Mediumitalic'),
    url(/static/media/rubik-latin-500italic.8e785e87.woff2) format('woff2'), 
    url(/static/media/rubik-latin-500italic.66685be7.woff) format('woff'); /* Modern Browsers */
}

/* rubik-700normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Rubik Bold '),
    local('Rubik-Bold'),
    url(/static/media/rubik-latin-700.7a34d41c.woff2) format('woff2'), 
    url(/static/media/rubik-latin-700.1451bac0.woff) format('woff'); /* Modern Browsers */
}

/* rubik-700italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Rubik Bold italic'),
    local('Rubik-Bolditalic'),
    url(/static/media/rubik-latin-700italic.870d6991.woff2) format('woff2'), 
    url(/static/media/rubik-latin-700italic.b2ef9b4d.woff) format('woff'); /* Modern Browsers */
}

/* rubik-900normal - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Rubik Black '),
    local('Rubik-Black'),
    url(/static/media/rubik-latin-900.ff511dd3.woff2) format('woff2'), 
    url(/static/media/rubik-latin-900.07d03e13.woff) format('woff'); /* Modern Browsers */
}

/* rubik-900italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src:
    local('Rubik Black italic'),
    local('Rubik-Blackitalic'),
    url(/static/media/rubik-latin-900italic.7a22860f.woff2) format('woff2'), 
    url(/static/media/rubik-latin-900italic.43bb06bb.woff) format('woff'); /* Modern Browsers */
}


/* roboto-mono-100normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Mono Thin '),
    local('Roboto Mono-Thin'),
    url(/static/media/roboto-mono-latin-100.d6c0b24c.woff2) format('woff2'), 
    url(/static/media/roboto-mono-latin-100.0599391c.woff) format('woff'); /* Modern Browsers */
}

/* roboto-mono-100italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src:
    local('Roboto Mono Thin italic'),
    local('Roboto Mono-Thinitalic'),
    url(/static/media/roboto-mono-latin-100italic.09ccd022.woff2) format('woff2'), 
    url(/static/media/roboto-mono-latin-100italic.0c62fd54.woff) format('woff'); /* Modern Browsers */
}

/* roboto-mono-300normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Mono Light '),
    local('Roboto Mono-Light'),
    url(/static/media/roboto-mono-latin-300.db95b37e.woff2) format('woff2'), 
    url(/static/media/roboto-mono-latin-300.4f691391.woff) format('woff'); /* Modern Browsers */
}

/* roboto-mono-300italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Roboto Mono Light italic'),
    local('Roboto Mono-Lightitalic'),
    url(/static/media/roboto-mono-latin-300italic.18357dda.woff2) format('woff2'), 
    url(/static/media/roboto-mono-latin-300italic.8debb2d5.woff) format('woff'); /* Modern Browsers */
}

/* roboto-mono-400normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Mono Regular '),
    local('Roboto Mono-Regular'),
    url(/static/media/roboto-mono-latin-400.e92cc0fb.woff2) format('woff2'), 
    url(/static/media/roboto-mono-latin-400.1db1469b.woff) format('woff'); /* Modern Browsers */
}

/* roboto-mono-400italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Roboto Mono Regular italic'),
    local('Roboto Mono-Regularitalic'),
    url(/static/media/roboto-mono-latin-400italic.55befc28.woff2) format('woff2'), 
    url(/static/media/roboto-mono-latin-400italic.5711b84f.woff) format('woff'); /* Modern Browsers */
}

/* roboto-mono-500normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Mono Medium '),
    local('Roboto Mono-Medium'),
    url(/static/media/roboto-mono-latin-500.f70f506c.woff2) format('woff2'), 
    url(/static/media/roboto-mono-latin-500.077525e5.woff) format('woff'); /* Modern Browsers */
}

/* roboto-mono-500italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Roboto Mono Medium italic'),
    local('Roboto Mono-Mediumitalic'),
    url(/static/media/roboto-mono-latin-500italic.c5465264.woff2) format('woff2'), 
    url(/static/media/roboto-mono-latin-500italic.93b351bd.woff) format('woff'); /* Modern Browsers */
}

/* roboto-mono-700normal - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Mono Bold '),
    local('Roboto Mono-Bold'),
    url(/static/media/roboto-mono-latin-700.4bc0bd04.woff2) format('woff2'), 
    url(/static/media/roboto-mono-latin-700.f61e5a8d.woff) format('woff'); /* Modern Browsers */
}

/* roboto-mono-700italic - latin */
@font-face {
  font-family: 'Roboto Mono';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Roboto Mono Bold italic'),
    local('Roboto Mono-Bolditalic'),
    url(/static/media/roboto-mono-latin-700italic.5eca10b5.woff2) format('woff2'), 
    url(/static/media/roboto-mono-latin-700italic.7324e30e.woff) format('woff'); /* Modern Browsers */
}


